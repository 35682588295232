import '../../utils/marketingPageDataFragment';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CommunityModule, { CommunityDataPropsObject } from '../../components/CommunityModule';
import FeaturedTemplatesModule, { FeaturedTemplatesDataPropsObject } from '../../components/FeaturedTemplatesModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import SuccessStoryModule, { SuccessStoryDataPropsObject } from '../../components/SuccessStoryModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import setHeroVideoSchemaInfo from '../../utils/setHeroVideoSchema';
import {
  community as communityClass,
  featuredTemplates as featuredTemplatesClass,
  hero as heroClass,
  pitch as pitchClass,
  successStory as successStoryClass
} from './MarketingInfluencerPage.module.css';

export default function MarketingInfluencerPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;

  const {
    community,
    featuredTemplates,
    hero,
    pitch,
    successStory
  } = componentsQueryResultToData(components);

  const SEOInfo = {
    title               : page.title,
    description         : description && description.description,
    slug                : page.slug,
    canonicalSlug       : canonicalUrlPage && canonicalUrlPage.slug,
    noIndex             : page.noIndex,
    heroVideoSchemaInfo : setHeroVideoSchemaInfo(hero)
  };

  if (imageFieldName && imageFieldName.file && imageFieldName.file.url) {
    SEOInfo.image = imageFieldName.file.url;
  }

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={heroClass} data={hero} />
      <FeaturedTemplatesModule
        className={featuredTemplatesClass}
        data={featuredTemplates}
      />
      <SuccessStoryModule className={successStoryClass} data={successStory} layout="asset-left" />
      <PitchModule className={pitchClass} data={pitch} />
      <CommunityModule className={communityClass} data={community} />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingInfluencerPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingInfluencerPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCommunity']),
          ...CommunityDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFeaturedTemplates']),
          ...FeaturedTemplatesDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentSuccessStory']),
          ...SuccessStoryDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
